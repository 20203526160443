import axios from "axios";
import authModule from "@/store/modules/auth_module.js";
import router from "./router";

const url = process.env.NODE_ENV !== 'production' ? 'http://localhost:8000/api/v1' : 'https://api.foodplace.com.ng/api/v1'

const AxiosClient = axios.create({
    baseURL: url,
    withCredentials: true,
})

AxiosClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${authModule.state.token}`
    return config;
})

AxiosClient.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.removeItem('TOKEN')
        router.push({name: 'Login'})
    } else if (error.response.status === 404) {
        router.push({name: 'NotFound'})
    }
    throw error;
})

export default AxiosClient;
