import axios from '@/AxiosClient'

export default {
    namespaced: true,
    state: {
        cities: null,
        city: null,
        created: null,
        error: ''
    },

    getters: {
        getCities: (state) => {
            return state.cities
        },
        getCreated: (state) => {
            return state.created
        },
    },

    mutations: {
        SET_CITIES(state, payload) {
            state.cities = payload
        },
        REQUEST_ERROR(state, payload) {
            state.error = payload
        },
        SET_CITY(state, payload) {
            state.city = payload
        },
        SET_CREATED(state, payload) {
            state.created = payload
        },
    },

    actions: {
        async cityStoreRequest({commit, dispatch}, payload) {
            await axios.post('/admin/city', payload)
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_CITY', response.data)
                commit('SET_CREATED', response.status) 
                dispatch('citiesRequest')               
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },
        
        async citiesRequest({commit}) {
            await axios.get('/admin/cities')
            .then(response => {
                commit('SET_CITIES', response.data.data)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        }
    }
}