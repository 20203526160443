import UserApi from '../../apis/User_Api'

export default {
    namespaced: true,
    state: {
        users: null,
        user_loader: false,
        update: null,
        suspend: null,
        unsuspend: null,
        deleted: null,
    },

    getters: {
        getUsers: (state) => {
            return state.users
        },
        getSuspend: (state) => {
            return state.suspend
        },
        getUnsuspend: (state) => {
            return state.unsuspend
        },
        getUserLoader: (state) => {
            return state.user_loader
        },
        getUpdate: (state) => {
            return state.update
        },
        getDeleted: (state) => {
            return state.deleted
        },
    },

    mutations: {
        SET_USERS(state, payload) {
            state.users = payload
        },
        SET_UPDATE(state, payload) {            
            state.update = payload
        },
        SET_SUSPEND(state, payload) {
            state.suspend = payload
        },
        SET_UNSUSPEND(state, payload) {
            state.unsuspend = payload
        },
        REQUEST_ERROR(state, payload) {
            state.error = payload
        },
        SET_USER_LOADER(state, payload) {
            state.user_loader = payload
        },
        SET_DELETE(state, payload) {
            state.deleted = payload
        }
    },

    actions: {        
        async usersRequest({commit}) {
            commit('SET_USER_LOADER', true)
            // await axios.get('/admin/users')
            await UserApi.getAllUsers()
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_USERS', response.data.data)
                commit('SET_USER_LOADER', false)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
                commit('SET_USER_LOADER', false)
            })
        },

        async updateUsersRequest({commit}, data) {
            // await axios.put('/admin/users', data)
            commit('SET_UPDATE', '')
            await UserApi.updateOne(data)
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_UPDATE', response) 
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },

        async suspendUserRequest({commit}, data) {
            commit('SET_SUSPEND', '')           
            await UserApi.suspendOne(data)
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_SUSPEND', response)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },

        async unsuspendUserRequest({commit}, data) {
            commit('SET_UNSUSPEND', '')            
            await UserApi.unsuspendOne(data)
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_UNSUSPEND', response)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },

        async deleteUserRequest({commit}, data) {
            commit('SET_DELETE', '')            
            await UserApi.deleteOne(data)
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_DELETE', response)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },
    }
}