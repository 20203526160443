<template>
    <div class="p-4 text-left">
        <div @click="gotoDashboard()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer"> 
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
            <span class="pl-4 text-sm">Dashboard </span>
        </div>
        <div @click="requestUsers()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer"> 
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
            <span class="pl-4 text-sm">Users </span>
        </div>    
        <div @click="requestCategories()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer"> 
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
            <span class="pl-4 text-sm">Category </span>
        </div>
        <div @click="requestStates()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer"> 
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path></svg>
            <span class="pl-4 text-sm">States </span>
        </div>
        <div v-if="states_status" @click="requestCities()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer">
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path></svg> 
            <span class="pl-4 text-sm">Cities </span>
        </div>
        <div @click="requestOrders()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer">
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span class="pl-4 text-sm">Orders </span>
        </div>
        <div @click="requestVendors()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer">
            <svg class="w-4 h-4 inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 2V22H19V15H15V8C15 6.4087 15.6321 4.88258 16.7574 3.75736C17.8826 2.63214 19.4087 2 21 2ZM19 4.53C18.17 5 17 6.17 17 8V13H19V4.53ZM9 13.9V22H7V13.9C5.87081 13.6691 4.85599 13.0554 4.12714 12.1625C3.3983 11.2697 3.00014 10.1526 3 9V3H5V10H7V3H9V10H11V3H13V9C12.9999 10.1526 12.6017 11.2697 11.8729 12.1625C11.144 13.0554 10.1292 13.6691 9 13.9Z" fill="white"/></svg>
            <span class="pl-4 text-sm">Restaurants </span>
        </div>
        <div @click="requestRiders()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer">
            <svg class="w-4 h-4 inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 1C16.5523 1 17 1.44772 17 2V3H22V9H19.9813L22.7271 16.5439C22.9033 16.9948 23 17.4856 23 17.999C23 20.2082 21.2091 21.999 19 21.999C17.1365 21.999 15.5706 20.7247 15.1263 19H10.874C10.4299 20.7252 8.86382 22 6.99998 22C5.05549 22 3.43506 20.6125 3.07473 18.7736C2.43595 18.4396 1.99998 17.7707 1.99998 17V7C1.99998 6.44772 2.4477 6 2.99998 6H9.99998C10.5523 6 11 6.44772 11 7V12C11 12.5523 11.4477 13 12 13H14C14.5523 13 15 12.5523 15 12V3H12V1H16ZM6.99998 16C5.89541 16 4.99998 16.8954 4.99998 18C4.99998 19.1046 5.89541 20 6.99998 20C8.10455 20 8.99998 19.1046 8.99998 18C8.99998 16.8954 8.10455 16 6.99998 16ZM19 15.999C17.8954 15.999 17 16.8944 17 17.999C17 19.1036 17.8954 19.999 19 19.999C20.1046 19.999 21 19.1036 21 17.999C21 17.7587 20.9576 17.5282 20.8799 17.3148L20.8635 17.2714C20.5725 16.5266 19.8479 15.999 19 15.999ZM17.853 9H17V12C17 13.6569 15.6568 15 14 15H12C10.3431 15 8.99998 13.6569 8.99998 12H3.99998V15.3542C4.73293 14.5238 5.80529 14 6.99998 14C8.86382 14 10.4299 15.2748 10.874 17H15.1257C15.5694 15.2743 17.1358 13.999 19 13.999C19.2368 13.999 19.4688 14.0196 19.6943 14.0591L17.853 9ZM8.99998 8H3.99998V10H8.99998V8ZM20 5H17V7H20V5Z" fill="white"/></svg>
            <span class="pl-4 text-sm">Riders </span>
        </div>
        
        <div @click="gotoSettings()" class="align-middle text-white whitespace-nowrap py-2 cursor-pointer">
            <svg class="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
            <span class="pl-4 text-sm">Settings </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SideNavBar',
    computed: {
        ...mapGetters({
            authenticated: 'authModule/authenticateStatus',
            states_status: 'statesModule/getStateStatus'
        })
    },
    methods: {
        gotoDashboard() {
            // this.$store.dispatch('authModule/analysis')
            this.$router.push({name: 'Home'})           
        },
        requestUsers() {
            this.$store.dispatch('usersModule/usersRequest')
            this.$router.push({name: 'User'})
        },
        requestCategories() {
            this.$store.dispatch('categoriesModule/categoriesRequest')
            this.$router.push({name: 'Category'})
        },
        requestStates() {
            this.$store.dispatch('statesModule/statesRequest')
            this.$router.push({name: 'State'})
        },
        requestCities() {
            this.$store.dispatch('citiesModule/citiesRequest')
            this.$router.push({name: 'City'})
        },
        requestVendors() {
            this.$store.dispatch('vendorsModule/vendorsRequest')
            this.$router.push({name: 'Restaurants'})
        },
        requestOrders() {
            this.$store.dispatch('ordersModule/ordersRequest')
            this.$router.push({name: 'Orders'})
        },
        requestRiders() {
            this.$store.dispatch('ridersModule/ridersRequest')
            this.$router.push({name: 'Riders'})
        },
        gotoSettings() {
            this.$store.dispatch('chargeModule/getChargesAction')
            this.$router.push({name: 'Settings'})           
        },
    }
}
</script>