<template>
    <div class="w-full bg-brown-default flex overflow-auto relative h-screen">
        <div class="flex flex-col p-4">
            <application-logo></application-logo>
            <side-nav-bar class="flex-1"></side-nav-bar>
            <div @click="logout()" class="text-white cursor-pointer bg-red-800 py-2 rounded-lg hover:text-gray-200 hover:bg-red-700 shadow-xl">
                <svg class="w-4 h-4 inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 8L11 7C11 6.20435 11.3161 5.44129 11.8787 4.87868C12.4413 4.31607 13.2044 4 14 4L18 4C18.7957 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7L21 17C21 17.7956 20.6839 18.5587 20.1213 19.1213C19.5587 19.6839 18.7956 20 18 20L14 20C13.2044 20 12.4413 19.6839 11.8787 19.1213C11.3161 18.5587 11 17.7956 11 17L11 16M7 8L3 12L7 8ZM3 12L7 16L3 12ZM3 12L17 12L3 12Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span class="pl-3">Logout</span>
            </div>
        </div>
        <div class="flex-1 bg-pink-50 m-4 rounded-md overflow-y-auto">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import ApplicationLogo from '@/components/ApplicationLogo.vue'
import SideNavBar from '@/layouts/includes/SideNavBar.vue'
export default {
    name: "DashboardLayout",
    components: {
        SideNavBar,
        ApplicationLogo
    },
    methods: {
        logout() {
            this.$store.dispatch('authModule/logoutRequest')
            
            this.$router.push({name: 'Login'})
            
        }
    }
}
</script>