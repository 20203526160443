import axios from '@/AxiosClient'

export default {

    getAllUsers() {
        return axios.get('/admin/users')
    },

    updateOne(data) {
        return axios.put('/admin/users', data)
    },

    suspendOne(data) {
        return axios.put('/admin/suspend-user', { user_id: data })
    },

    unsuspendOne(data) {
        return axios.put('/admin/unsuspend-user', { user_id: data })
    },

    deleteOne(data) {
        return axios.delete('/admin/user/' + data)
    }
}