import axios from '@/AxiosClient'

export default {
    namespaced: true,
    state: {
        vendors: null,
        vendor_update: null,
        error: ''
    },

    getters: {
        getVendors: (state) => {
            return state.vendors
        },

        getVendorUpdate: (state) => {
            return state.vendor_update
        },
    },

    mutations: {
        SET_VENDORS(state, payload) {
            state.vendors = payload
        },
        SET_VENDOR_UPDATE(state, payload) {
            state.vendor_update = payload
        },
        VENDOR_REQUEST_ERROR(state, payload) {
            state.error = payload
        },
    },

    actions: {        
        async vendorsRequest({commit}) {
            await axios.get('/admin/vendors')
            .then(response => {
                commit('VENDOR_REQUEST_ERROR', '')
                commit('SET_VENDORS', response.data.data)
            })
            .catch(error => {
                commit('VENDOR_REQUEST_ERROR', error)
            })
        },

        async approveVendorRequest({commit}, data) {
            await axios.put('/admin/approve-vendor', data)
            .then(response => {
                commit('VENDOR_REQUEST_ERROR', '')
                commit('SET_VENDOR_UPDATE', response)
            })
            .catch(error => {
                commit('VENDOR_REQUEST_ERROR', error)
            })
        }
    }
}