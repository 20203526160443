// import axios from 'axios'
import AuthApi from '../../apis/Auth_Api'

export default {
    namespaced: true,
    state: {
        token: sessionStorage.getItem('token') || '',
        status: '',
        authenticated: false,
        user: null,
        login_loading: false,
        analysis: [],
        analysis_error: ''
    },

    getters: {
        authStatus: function(state) {
            return state.status;
        },
        authenticatedStatus: function(state) {
            return state.authenticated
        },
        getLoading: function(state) {
            return state.login_loading;
        },
        getRole: function(state) {
            return state.user.role;
        },
        getAnalysis: function(state) {
            return state.analysis;
        }
    },

    mutations: {
        AUTH_REQUEST(state, payload) {
            state.status = payload;
        },
        AUTH_SUCCESS(state, payload) {
            state.status = payload;
            state.authenticated = true;
        },
        AUTH_ERROR(state, payload) {
            state.status = payload;
        },
        AUTH_LOGOUT(state, payload) {
            state.status = payload;
            state.authenticated = false;
        },
        SET_USER(state, payload) {
            state.user = payload;
        },
        SET_TOKEN(state, payload) {
            state.token = payload;
        },
        SET_LOGIN_LOADING(state, payload) {
            state.login_loading = payload;
        },
        SET_ANALYSIS(state, payload) {
            state.analysis = payload;
        },
        SET_ANALYSIS_ERROR(state, payload) {
            state.analysis_error = payload;
        }
    },

    actions: {
        async authRequest({commit}, payload) {
            commit('AUTH_ERROR', '')
            commit('SET_LOGIN_LOADING', true)
            // await axios.post('/login', payload)
            await AuthApi.loginUser(payload)
            .then(response => {
                sessionStorage.setItem('token', response.data.access_token)
                commit('AUTH_SUCCESS', response.data.status)
                commit('SET_USER', response.data.user)
                commit('SET_TOKEN', response.data.access_token)
                commit('SET_LOGIN_LOADING', false)
                // axios.defaults.headers.common['Authorization'] = "Bearer " + response.access_token
                return true
            })
            .catch(error => {
                commit('AUTH_ERROR', error)
                commit('SET_LOGIN_LOADING', false)
                return false
            })
        },

        async analysis({commit}) {
            await AuthApi.getAnalysis()
            .then(response => {
                commit('SET_ANALYSIS_ERROR', '')
                commit('SET_ANALYSIS', response.data)
            })
            .catch(error => {
                commit('SET_ANALYSIS_ERROR', error)
            })
        },

        async logoutRequest({commit}) {
            commit('AUTH_ERROR', '')
            await AuthApi.logoutUser()
            .then(() => {             
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
            })
            .catch(error => {
                commit('AUTH_ERROR', error)
            })
        }
    }
}