import axios from '@/AxiosClient'

export default {
    getChargesRequest() {
        return axios.get('/admin/charges')
    },

    postChargesRequest(data) {
        return axios.post('/admin/charges', data)
    },
}