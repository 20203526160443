import axios from '@/AxiosClient'

export default {
    namespaced: true,
    state: {
        orders: null,
        error: '',
        order_loader: false
    },

    getters: {
        getOrders: (state) => {
            return state.orders
        },
        getOrderLoader: (state) => {
            return state.order_loader
        }
    },

    mutations: {
        SET_ORDERS(state, payload) {
            state.orders = payload
        },
        SET_ORDER_LOADER(state, payload) {
            state.order_loader = payload
        },
        ORDER_REQUEST_ERROR(state, payload) {
            state.error = payload
        },
    },

    actions: {        
        async ordersRequest({commit}) {
            commit('SET_ORDER_LOADER', true)
            await axios.get('/admin/orders')
            .then(response => {
                commit('ORDER_REQUEST_ERROR', '') 
                commit('SET_ORDERS', response.data.data)
                commit('SET_ORDER_LOADER', false)
            })
            .catch(error => {
                commit('ORDER_REQUEST_ERROR', error)
                commit('SET_ORDER_LOADER', false)
            })
        }
    }
}