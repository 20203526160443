<template>
    <div class="grid">
        <p>default</p>
        <router-view></router-view>
        <Footer />
    </div>
</template>

<script> 
import Footer from '@/layouts/includes/Footer.vue'
export default {
    components: {
        Footer
    },
    setup() {
        
    },
}
</script>