import ChargeApi from '../../apis/Charge_Api'

export default {
    namespaced: true,

    state: {
        charges: {},
        charge_created: '',
        charge_loader: false,
        error: ''
    },

    getters: {
        getCharges(state) {
            return state.charges
        },

        getChargeLoader(state) {
            return state.charge_loader
        },

        getChargeCreated(state) {
            return state.charge_created
        }
    },

    mutations: {
        SET_CHARGES(state, payload) {
            state.charges = payload
        },

        SET_CHARGE_CREATED(state, payload) {
            state.charge_created = payload
        },

        SET_CHARGE_LOADER(state, payload) {
            state.charge_loader = payload
        },

        SET_ERROR(state, payload) {
            state.error = payload
        },
    },

    actions: {
        async getChargesAction({ commit }, data) {
            commit('SET_ERROR', '')
            commit('SET_CHARGE_LOADER', true)
            await ChargeApi.getChargesRequest(data)
            .then(response => {
                commit('SET_CHARGES', response.data.data)
                commit('SET_CHARGE_LOADER', false)
            })
            .catch(error => {
                commit('SET_ERROR', error)
                commit('SET_CHARGE_LOADER', false)
            })
        },

        async postChargesAction({ commit, dispatch }, data) {
            commit('SET_ERROR', '')
            await ChargeApi.postChargesRequest(data)
            .then(response => {
                commit('SET_CHARGE_CREATED', response)
                dispatch('getChargesAction')
            })
            .catch(error => {
                commit('SET_ERROR', error)
            })
        }
    }
}