import { createWebHistory, createRouter } from "vue-router";
import store from '@/store';

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@/views/auth/Signin.vue'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: false
        }
    },
    {
        path: "/admin/dashboard",
        name: "Home",
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: "/admin/states",
        name: 'State',
        component: () => import('@/views/State.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: "/admin/users",
        name: 'User',
        component: () => import('@/views/User.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: "/admin/categories",
        name: 'Category',
        component: () => import('@/views/Category.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: "/admin/cities",
        name: 'City',
        component: () => import('@/views/City.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: '/admin/restaurants',
        name: 'Restaurants',
        component: () => import('@/views/Vendor.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: '/admin/orders',
        name: 'Orders',
        component: () => import('@/views/Order.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: '/admin/riders',
        name: 'Riders',
        component: () => import('@/views/Rider.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: '/admin/rider-details',
        name: 'Rider_Details',
        component: () => import('@/views/Rider_Details.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: '/admin/settings',
        name: 'Settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
            layout: 'DashboardLayout',
            requiresAuth: true
        }
    },
    {
        path: "/:catchAll(.*)",
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['authModule/authenticatedStatus']) {
            return next()
        } else {
            return next({path: '/'})
        }
    } 

    if (!to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['authModule/authenticatedStatus']) {
            return next(false)
        } else {
            return next()
        }
    }

})

export default router;