import { createStore } from 'vuex'
import CreatePersistedState from 'vuex-persistedstate'
import authModule from './modules/auth_module.js'
import statesModule from './modules/states_module.js'
import citiesModule from './modules/cities_module.js'
import vendorsModule from './modules/vendors_module.js'
import ordersModule from './modules/orders_module.js'
import ridersModule from './modules/riders_module.js'
import usersModule from './modules/users_module.js'
import categoriesModule from './modules/categories_module.js'
import chargeModule from './modules/charge_module.js'

export default createStore({    
    modules: {
        authModule,
        statesModule,
        citiesModule,
        vendorsModule,
        ordersModule,
        ridersModule,
        usersModule,
        categoriesModule,
        chargeModule
    },

    plugins: [CreatePersistedState()]
})
