<template>
    <component :is="layout" />
</template>

<script>
// import '@/assets/tailwind.css';
import AuthLayout from './layouts/AuthLayout.vue'
import DashboardLayout from './layouts/DashboardLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'

export default {
    name: 'App',
    components: {
        AuthLayout,
        DashboardLayout,
        DefaultLayout
    },
    data() {
        return {
            layout: null,
        };
    },
    watch: {
        $route(to) {
            // set layout by route meta
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout
            } else {
                this.layout = "DefaultLayout" // this is default layout if route meta is not set
            }
        },
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
