import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import store from './store'
// import authModule from './store/modules/auth_module.js'
import 'tw-elements';
// import axios from 'axios'

// const url = process.env.NODE_ENV !== 'production' ? 'http://localhost:8000/api/v1' : 'https://api.foodplace.com.ng/api/v1'

// const axiosClient = axios.create({
//     baseURL: url
// })

// axiosClient.interceptors.request.use(config => {
//     config.headers.Authorization = `Bearer ${authModule.state.token}`
//     return config;
// })

// axiosClient.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         localStorage.removeItem('token')
//         router.push({name: 'Login'})
//     } else if (error.response.status === 404) {
//         router.push({name: 'NotFound'})
//     }
//     throw error;
// })

createApp(App).use(store).use(router).mount('#app')