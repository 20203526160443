import axios from '@/AxiosClient'

export default {
    namespaced: true,
    state: {
        states: null,
        error: '',
        updated: '',
        states_status: false
    },

    getters: {
        getStates: (state) => {
            return state.states
        },
        getAvailableStates: (state) => {
            return state.states.filter(state => state.status == 'available')
        },
        getStateStatus: (state) => {
            return state.states_status
        }
    },

    mutations: {
        SET_STATES(state, payload) {
            state.states = payload;
        },
        REQUEST_ERROR(state, payload) {
            state.error = payload
        }, 
        SET_UPDATED(state, payload) {
            state.updated = payload
        },
        SET_STATES_STATUS(state, payload) {
            state.states_status = payload
        }
    },

    actions: {
        async statesRequest({commit}) {
            await axios.get('/admin/states')
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_STATES', response.data.data)                
                commit('SET_STATES_STATUS', true)                
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },
        async stateUpdateRequest({commit}, payload)  {
            await axios.put('/admin/state', payload)
            .then(resp => {
                commit('SET_UPDATED', resp.data)
            })
        }
    }
}