import axios from '@/AxiosClient.js'

export default {
    loginUser(data) {
        return axios.post('/login', data)
    },

    getAnalysis() {
        return axios.get('/admin/analysis')
    },

    logoutUser() {
        return axios.post('/logout')
    }
}