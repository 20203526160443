import axios from '@/AxiosClient'

export default {
    namespaced: true,
    state: {
        categories: null,
        category: null,
        updated: null,
        created: null,
        error: ''
    },

    getters: {
        getCategories: (state) => {
            return state.categories
        },
        getCreated: (state) => {
            return state.created
        },
        getUpdated: (state) => {
            return state.updated
        },
        getError: (state) => {
            return state.error
        },
    },

    mutations: {
        SET_CATEGORIES(state, payload) {
            state.categories = payload
        },
        REQUEST_ERROR(state, payload) {
            state.error = payload
        },
        SET_CATEGORY(state, payload) {
            state.category = payload
        },
        SET_CREATED(state, payload) {
            state.created = payload
        },
        SET_UPDATED(state, payload) {
            state.updated = payload
        }
    },

    actions: {
        async categoryStoreRequest({commit, dispatch}, payload) {
            commit('SET_CREATED', null)
            await axios.post('/admin/category', payload)
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_CREATED', response.data)
                dispatch('categoriesRequest')               
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },
        
        async categoriesRequest({commit}) {
            await axios.get('/admin/categories')
            .then(response => {
                commit('SET_CATEGORIES', response.data.data)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        },

        async categoryUpdateRequest({commit}, data) {
            commit('SET_UPDATED', '')
            await axios.put('/admin/category', data)
            .then(response => {
                commit('SET_UPDATED', response)
            })
            .catch(error => {
                console.log('error ' + error.message)
                commit('REQUEST_ERROR', error.message)
            })
        }
    }
}