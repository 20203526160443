import axios from '@/AxiosClient'

export default {
    namespaced: true,
    state: {
        riders: null,
        rider: null,
        vendor_lists: null,
        error: ''
    },

    getters: {
        getRiders: (state) => {
            return state.riders
        },
        getRider: (state) => {
            return state.rider
        },
        getVendorLists: (state) => {
            return state.vendor_lists
        },
    },

    mutations: {
        SET_RIDERS(state, payload) {
            state.riders = payload
        },
        SET_RIDER(state, payload) {
            state.rider = payload
        },
        REQUEST_ERROR(state, payload) {
            state.error = payload
        },
        SET_VENDORS_LIST(state, payload) {
            state.vendor_lists = payload
        }
    },

    actions: {        
        async ridersRequest({commit}) {            
            await axios.get('/admin/riders')
            .then(response => {
                commit('REQUEST_ERROR', '')
                commit('SET_RIDERS', response.data.data)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })            
        },

        async setRiderRequest({commit}, data) { 
            commit('SET_RIDER', data)
            await axios.get('/admin/vendors-by-state/'+data.state_id)
            .then(response => {
                commit('SET_VENDORS_LIST', response.data.data)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            }) 
                
        },

        async assign({commit}, data) {
            await axios.post('/admin/assign-rider', data)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                commit('REQUEST_ERROR', error)
            })
        }
    }

    // commit('REQUEST_ERROR', '')
    // axios.all([axios.get('/admin/riders'), axios.get('/admin/vendors-by-state/'+data)])
    // .then(axios.spread((...responses) => {
    //     commit('SET_RIDERS', responses[0].data)
    //     commit('SET_VENDORS_LIST', responses[1].data)
    // })).catch(error => {
    //     commit('REQUEST_ERROR', error)
    // }) 
}